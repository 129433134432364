import { InjectionToken } from "@angular/core";
export let APP_CONFIG = new InjectionToken("app.config");
export const AppConfig = {
  routes: {
    error404: "service-rsa-request"
  },
  ClientDetailsUrl: {
    clienturl: "https://agero-stg.apigee.net/rsahelps3/clients?id=",
    clientapikey: "2FCgqp9gwheeC1wO4wHgr5abSHGJrxHY"
  },
  LogosBaseURL: {
    baseurl: "https://d26xm602exz1gb.cloudfront.net/STG"
  },
  VIN: {
    url: "https://agero-stg.apigee.net/vindecoderapi/V1/VINDecoderPost",
    apiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  MCMAKE: {
    url:
      "https://api-test.enterprise.agero.com/microservices/vehicleapi/v1/makes?modelType=MC&limit=600&offset=0",
    apiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  MAKE: {
    // url:
    //   "https://api-test.enterprise.agero.com/microservices/vehicleapi/v1/makes?modelType=PC%2cMPV%2cPKUP%2cVAN&limit=600&offset=0",
    // apiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
    url:
    "https://api-gateway.enterprise.agero.com/microservices/vehicleapi/v1/makes?modelType=PC%2cMPV%2cPKUP%2cVAN&limit=600&offset=0",
    apiKey: "LgTOIAi8vtxlcppvlG4kHOxVuuRzb6GY"
  },
  MODEL: {
    // url:
    //   "https://api-test.enterprise.agero.com/microservices/vehicleapi/v1/makes/",
    // apiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
    url: "https://api-gateway.enterprise.agero.com/microservices/vehicleapi/v1/makes/",
    apiKey: "LgTOIAi8vtxlcppvlG4kHOxVuuRzb6GY"
  },
  VEHICLEINFOMAKE: {
    url: "https://agero-stg.apigee.net/vehicleinfo/api/v1/Vehicle/makes?modelType=PC%2cMPV%2cPKUP%2cVAN&limit=200&offset=100",
    apiKey: "UoGnB0N6Xea67h4dUuLzcHSR0KtyZmxN"
  },
  VEHICLEINFOMODEL: {
    url: "https://agero-stg.apigee.net/vehicleinfo/api/v1/Vehicle/makes/",
    apiKey: "UoGnB0N6Xea67h4dUuLzcHSR0KtyZmxN"
  },
  VPICMAKE: {
    url:
      "https://agero-stg.apigee.net/ondemandapi/GetVehicleMake",
    apiKey: "DkzklDJXqG6qtWkCDey6CBVD7Ag2nyLF"
  },
  VPICMODEL: {
    url:
      "https://agero-stg.apigee.net/ondemandapi/GetVehicleListBasedonMakeYear",
    apiKey: "DkzklDJXqG6qtWkCDey6CBVD7Ag2nyLF"
  },
  COLOR: {
    url: "https://agero-dev.apigee.net/crmlookupapi/Dev/lookups/vehicleColor",
    apiKey: "rMcAHxMLsj6zF8qoc66WqIGPX2K3CqkS"
  },
  SPLUNK: {
    url: "https://agero-stg.apigee.net/rsahelpapi/services/collector/event",
    //url:"https://api.enterprise.agero.com/rsahelpapi/services/collector/event"
    apiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"

  },
  CLOUDWATCH: {
    //url: "https://ondemandrsaapi-stage.us-east-1.elasticbeanstalk.com/eventlog",
    url:"https://agero-stg.apigee.net/ondemandapi/eventlog",
    apiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"

  },
  RSAHELP: {
    url: "https://agero-stg.apigee.net/ondemandapi/RSARequestDetails/",
    apiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i",
    updateRSAHelpUrl:
      "https://agero-stg.apigee.net/ondemandapi/UpdateRSARequest",
    updateRsaHelpApiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  TRACKING: {
    // trackingUrl:'https://agero-stg.apigee.net/agerodashboardapi/GetTrackingId',
    trackingUrlWhenVDN:
      "https://agero-stg.apigee.net/ondemandapi/GetTrackingDetails",
    trackingUrl:
      "https://agero-stg.apigee.net/ondemandapi/InitiateActivityTrack",
    saveTrackUrl:
      "https://agero-stg.apigee.net/ondemandapi/saveRSATrackRequest",
    confirmSubmissionUrl:
      "https://agero-stg.apigee.net/ondemandapi/ConfirmUserSubmission",
    apiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  LIVETRACKING: {
    url: 'https://agero-stg.apigee.net/ondemandapi/v1/GetRequestStatus',
    apiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  AUDIO_TYPE: {
    url: "https://agero-stg.apigee.net/ondemandapi/ivrValue",
    apiKey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  ANI_NGP: {
    url: "https://agero-stg.apigee.net/ondemandapi/GetPolicyDetailsFromCallBackNumberforNGP/",
    apikey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  SWOOP_TOWDESTINATION: {
    url: "https://agero-stg.apigee.net/ondemandapi/ngp/GetTowDestinationDetailsFromNGP",
    apikey: "DkzklDJXqG6qtWkCDey6CBVD7Ag2nyLF"
  },
  VINWITH4DIGITS: {
    url: "https://agero-stg.apigee.net/ondemandapi/GetPolicyDetailsFromPolicyNumberForUSAA",
    apikey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  AGENT_ICON: {
    url: "https://agero-stg.apigee.net/rsahelpapi/agenticon",
    apikey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  EVCHARGESTATION: {
    url: "https://agero-stg.apigee.net/ondemandapi/GetNearestEVStation",
    apikey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  CLICKTOCALL: {
    url: "https://agero-stg.apigee.net/ondemandapi/GetInitiateOutBoundCall",
    apikey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  DRIVER_AVAILABILITY: {
    url: "https://agero-stg.apigee.net/ondemandapi/ngp/GetAltTransportDriverAvailability",
    apikey: "DkzklDJXqG6qtWkCDey6CBVD7Ag2nyLF"
  },
  WEATHER_STATUS: {
    url: "https://agero-stg.apigee.net/regional-eta-api/v1/eta/maximumacceptable",
    apikey: "lhgKb54wsOFAsrbRgdE2GuWgYEIT6hmj"
  },
  TIME_RANGE: {
    url: "https://api-gateway-test.enterprise.agero.com/INS/V1/timeranges/names",
    apikey: "dDjLfyFv68mZRMJGG40uF3H7eTQFlkK1"
  },
  RECAPTCHA_VALIDATE: {
    url: "https://agero-stg.apigee.net/ondemandapi/ValidateRecaptcha",
    apikey: "DkzklDJXqG6qtWkCDey6CBVD7Ag2nyLF"
  },
  ProfileSearchBasedonPhoneNumber: {
    url: "https://agero-stg.apigee.net/ondemandapi/ngp/ProfileSearchBasedonPhoneNumber/",
    apikey: "lINtQ9dWRSXgOtZWcGEIbndpi084A24i"
  },
  CUSTOMER_RATE: {
    url: "https://agero-stg.apigee.net/ondemandapi/ngp/customerrate/",
    apikey: "DkzklDJXqG6qtWkCDey6CBVD7Ag2nyLF"
  },
  PROBLEMS: [
    { value: "Tow (Not leaking fuel)", text: "I need a tow" },
    {
      value: "Jump Start (Did not stall while driving)",
      text: "I need a jump start"
    },
    {
      value: "Multiple Flat Tires",
      text: "I have multiple flat tires or no spare tire"
    },
    {
      value: "One Flat Tire - Good spare",
      text: "I have one flat tire and a good spare tire"
    },
    { value: "Lockout - Keys in car", text: "I am locked out of my vehicle" },
    { value: "Vehicle Stuck", text: "My vehicle is stuck" },
    {
      value: "Out of Fuel (Cost of fuel not covered)",
      text: "I ran out of fuel"
    },
    { value: "Accident/Vandalism", text: "I was in an accident" },
    {
      value: "Jump Start (stall while driving)",
      text: "I need a jump start"
    },
  ],
  NGP: {
    url: "https://agero-stg.apigee.net/ondemandapi/ngp/CreateSwoopPreDraft",
    profileUrl: "https://agero-stg.apigee.net/ondemandapi/ngp/ProfileSearch",
    CoverageSearch: "https://agero-stg.apigee.net/ondemandapi/ngp/CoverageSearch",
    UpdateProfile: "https://agero-stg.apigee.net/ondemandapi/ngp/UpdateProfile",
    SubmitRequest: "https://agero-stg.apigee.net/ondemandapi/ngp/SubmitRequest",
    NonIvrFlow: 'https://agero-stg.apigee.net/ondemandapi/ngp/SubmitRequestToSwoopAPI',
    GetProgramSubCode: "https://agero-stg.apigee.net/ondemandapi/ngp/GetProgramSubCode",
    GetNGPJobStatus: "https://agero-stg.apigee.net/ondemandapi/ngp/RSA/",
    apikey: "DkzklDJXqG6qtWkCDey6CBVD7Ag2nyLF"
  },
  MAKES: [],
  AUDIO_URL: window.location.origin + "/audio/",
  //  AUDIO_URL:"../../audio/",
  //  AUDIO_URL:"http://localhost:4200/audio/",
  AUDIOS: [
    { value: "0", text: "Auto IVR" },
    { value: "1", text: "Manual IVR" },
    { value: "2", text: "Visual Only" },
    { value: "3", text: "Auto IVR Only" }
  ],
  VEHICLE_TYPES: [
    { id: "Passenger Car", name: "Passenger Car" },
    { id: "Pickup Truck", name: "Pickup Truck" },
    { id: "Recreational Vehicle", name: "Recreational Vehicle" },
    { id: "Motorcycle", name: "Motorcycle" },
    { id: "Other", name: "Other" }
  ],
  VEHICLE_COLORS: [
    { id: "Beige", name: "Beige" },
    { id: "Black", name: "Black" },
    { id: "Blue", name: "Blue" },
    { id: "Bronze", name: "Bronze" },
    { id: "Brown", name: "Brown" },
    { id: "Gold", name: "Gold" },
    { id: "Green", name: "Green" },
    { id: "Grey", name: "Grey" },
    { id: "Ivory", name: "Ivory" },
    { id: "Maroon", name: "Maroon" },
    { id: "Orange", name: "Orange" },
    { id: "Pewter", name: "Pewter" },
    { id: "Purple", name: "Purple" },
    { id: "Red", name: "Red" },
    { id: "Silver", name: "Silver" },
    { id: "Tan", name: "Tan" },
    { id: "White", name: "White" },
    { id: "Yellow", name: "Yellow" }
  ],
  audioFiles: [
    { id: "1", filename: "p1_servicetype.mp3" },
    { id: "2", filename: "p2_personavailability.mp3" },
    { id: "3", filename: "p2_jumpstart.mp3" },
    { id: "4", filename: "p2_sparetire.mp3" },
    { id: "5", filename: "p2_selecttire.mp3" },
    { id: "6", filename: "p2_keyaccessible.mp3" },
    { id: "7", filename: "p2_animallocked.mp3" },
    { id: "8", filename: "p2_fueltype.mp3" },
    { id: "9", filename: "p2_vehicledistance.mp3" },
    { id: "10", filename: "p2_vehicledrivable.mp3" },
    { id: "11", filename: "p3_location.mp3" },
    { id: "12", filename: "p4_locationtype.mp3" },
    { id: "13", filename: "p5_towdestination.mp3" },
    { id: "14", filename: "p5_contactinfo.mp3" },
    { id: "15", filename: "p6_vehicleinfo.mp3" },
    { id: "16", filename: "p7_comments.mp3" },
    { id: "17", filename: "p8_reviewsubmit.mp3" },
    { id: "18", filename: "p9_thankyou.mp3" },
    { id: "19", filename: "p5_notificationpref.mp3" },
    { id: "20", filename: "p5_policyno.mp3" },
    { id: "21", filename: "Visual_Help.mp3" }
  ],
  APPNAME: [
    { value: 'VDC' },
    { value: 'DO' }
  ],
  PHONEFIX_ENABLED_LIST: [
    {

      "service": ["battery_menu"],
      "vehicle": [{ "make": "All", "model": "All" }
      ],
      "yearFrom": 0,
      "yearTo": 0,
      "programCode": ["PRG"],
      "issue": "Battery/Headlights",
      "displayText": {
        "question": "We found a potentially easy solution for your vehicle's issue. Would you like to try it? Tap the button below. You can always return to this screen with your browser's back button",
        "additional": "",
        "yes": "Yes, please show me the fix",
        "no": "Dismiss"
      }
    },
    {

      "service": ["lockout_menu"],
      "vehicle": [{ "make": "Chrysler", "model": "All" },
      { "make": "Ram", "model": "All" },
      { "make": "Jeep", "model": "All" },
      { "make": "Dodge", "model": "All" }
      ],
      "yearFrom": 2015,
      "yearTo": 2020,
      "programCode": ["PRG"],
      "issue": "Key Fob",
      "displayText": {
        "question": "Let's try to get you back on the road faster. Do you have a KEY FOB available? You can always return to this screen with your browser's back button.",
        "additional": "",
        "yes": "Yes, please show me the fix",
        "no": "Dismiss"
      }
    }
  ],
  PHONEFIX: {
    url: "https://d3ygjy7s82yoz.cloudfront.net/index.html"
    //url : "http://localhost:8000/"
  }
};
